<template>
  <div id="ApptCall" class="p-10 pt-0">
    <div class="loading" v-if="!room">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="text-right pb-4 pt-3">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="backOrg"
          class="cursor-pointer"
        >
          <path
            d="M6 18.4998L17.9998 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.9998 18.4998L6 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-blue-700 fs-24 fw-500 mb-3">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.7971 7.69833C24.3188 7.44167 23.3154 7.17333 21.9504 8.13L20.2354 9.34333C20.1071 5.715 18.5321 4.29167 14.7054 4.29167H7.70542C3.71542 4.29167 2.16376 5.84333 2.16376 9.83333V19.1667C2.16376 21.85 3.62209 24.7083 7.70542 24.7083H14.7054C18.5321 24.7083 20.1071 23.285 20.2354 19.6567L21.9504 20.87C22.6738 21.3833 23.3038 21.5467 23.8054 21.5467C24.2371 21.5467 24.5754 21.4183 24.7971 21.3017C25.2754 21.0567 26.0804 20.3917 26.0804 18.7233V10.2767C26.0804 8.60833 25.2754 7.94333 24.7971 7.69833ZM12.9554 13.7767C11.7538 13.7767 10.7621 12.7967 10.7621 11.5833C10.7621 10.37 11.7538 9.39 12.9554 9.39C14.1571 9.39 15.1488 10.37 15.1488 11.5833C15.1488 12.7967 14.1571 13.7767 12.9554 13.7767Z"
                fill="#20419B"
              />
            </svg>
            <span v-if="!logEnd">
              <span class="fs-18 fw-700 mx-2.5">Video call:</span>
              {{formatTime(total_time)}}
            </span>
            <span v-else>
              <span class="fs-18 fw-700 mx-2.5">Phiên tư vấn đã diễn ra trong</span>
              {{formatTime(logEnd.answer_duration)}}
            </span>
          </div>
          <div class="appt-media-wraper">
            <div class="main-media">
              <Participant
                :participant="mainParticipantsTrack"
                v-if="mainParticipantsTrack"
                :tracks="mainParticipantsTrack.tracks"
              />
            </div>
            <div class="participant-media">
              <div
                v-for="(participant, i) in participantsTracks"
                :key="participant.id + 'mini-participant' + i"
                class="participant-media-item"
              >
                <Participant
                  @zoomTrack="zoomPTrack"
                  :participant="participant"
                  :tracks="participant.tracks"
                  :key="participant.id + 'mini-participant' + i + 'p-cpn'"
                />
              </div>
            </div>
          </div>

          <template v-if="!logEnd">
            <div class="bottom-action mt-6">
              <!-- mic -->
              <button
                @click.prevent="micChangeStatus()"
                class="b-action mr-12 w-12 h-12 rounded-full border-0 focus:outline-none"
                :class="
                mic_status && has_audio ? 'bg-blue-50' : 'bg-blue-100'
              "
                :disabled="!has_audio || !room || changing_mic"
              >
                <svg
                  v-if="mic_status && has_audio"
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.9287 10.64C22.4737 10.64 22.1121 11.0017 22.1121 11.4567V13.3C22.1121 17.43 18.7521 20.79 14.6221 20.79C10.4921 20.79 7.13205 17.43 7.13205 13.3V11.445C7.13205 10.99 6.77039 10.6284 6.31539 10.6284C5.86039 10.6284 5.49872 10.99 5.49872 11.445V13.2884C5.49872 18.0367 9.15039 21.945 13.8054 22.365V24.85C13.8054 25.305 14.1671 25.6667 14.6221 25.6667C15.0771 25.6667 15.4387 25.305 15.4387 24.85V22.365C20.0821 21.9567 23.7454 18.0367 23.7454 13.2884V11.445C23.7337 11.0017 23.3721 10.64 22.9287 10.64Z"
                    fill="#20419B"
                  />
                  <path
                    d="M14.6221 2.33331C11.7754 2.33331 9.46539 4.64331 9.46539 7.48998V13.4633C9.46539 16.31 11.7754 18.62 14.6221 18.62C17.4687 18.62 19.7787 16.31 19.7787 13.4633V7.48998C19.7787 4.64331 17.4687 2.33331 14.6221 2.33331ZM16.1504 10.4416C16.0687 10.745 15.8004 10.9433 15.4971 10.9433C15.4387 10.9433 15.3804 10.9316 15.3221 10.92C14.8671 10.7916 14.3887 10.7916 13.9337 10.92C13.5604 11.025 13.1987 10.8033 13.1054 10.4416C13.0004 10.08 13.2221 9.70665 13.5837 9.61331C14.2721 9.42665 14.9954 9.42665 15.6837 9.61331C16.0337 9.70665 16.2437 10.08 16.1504 10.4416ZM16.7687 8.17831C16.6637 8.45831 16.4071 8.62165 16.1271 8.62165C16.0454 8.62165 15.9754 8.60998 15.8937 8.58665C15.0771 8.28331 14.1671 8.28331 13.3504 8.58665C13.0004 8.71498 12.6037 8.52831 12.4754 8.17831C12.3471 7.82831 12.5337 7.43165 12.8837 7.31498C14.0037 6.90665 15.2404 6.90665 16.3604 7.31498C16.7104 7.44331 16.8971 7.82831 16.7687 8.17831Z"
                    fill="#20419B"
                  />
                </svg>

                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="!mic_status || !has_audio"
                >
                  <path
                    d="M21.8934 8.56002V10.1067L12.1867 19.8134C10.9067 18.6534 10.1067 16.9467 10.1067 15.12V8.56002C10.1067 5.81335 11.9734 3.53335 14.5067 2.88002C14.76 2.81335 15 3.02669 15 3.28002V5.33335C15 5.88002 15.4534 6.33335 16 6.33335C16.5467 6.33335 17 5.88002 17 5.33335V3.28002C17 3.02669 17.24 2.81335 17.4934 2.88002C20.0267 3.53335 21.8934 5.81335 21.8934 8.56002Z"
                    fill="#20419B"
                  />
                  <path
                    d="M26.4134 13.0799V15.1999C26.4134 20.6266 22.2401 25.0932 16.9334 25.5599V28.3999C16.9334 28.9199 16.5201 29.3332 16.0001 29.3332C15.4801 29.3332 15.0668 28.9199 15.0668 28.3999V25.5599C13.6134 25.4266 12.2401 24.9999 11.0134 24.3199L12.3868 22.9466C13.4801 23.4532 14.7068 23.7466 16.0001 23.7466C20.7201 23.7466 24.5601 19.9066 24.5601 15.1999V13.0799C24.5601 12.5732 24.9734 12.1599 25.4934 12.1599C26.0001 12.1599 26.4134 12.5732 26.4134 13.0799Z"
                    fill="#20419B"
                  />
                  <path
                    d="M21.8934 13.4399V15.3733C21.8934 18.8133 18.9334 21.5733 15.4134 21.2399C15.0401 21.1999 14.6667 21.1333 14.3201 21.0133L21.8934 13.4399Z"
                    fill="#20419B"
                  />
                  <path
                    d="M29.0266 2.97334C28.6266 2.57334 27.9732 2.57334 27.5732 2.97334L9.63991 20.9067C8.26658 19.4 7.43991 17.4 7.43991 15.2V13.08C7.43991 12.5733 7.02658 12.16 6.50658 12.16C5.99991 12.16 5.58658 12.5733 5.58658 13.08V15.2C5.58658 17.9067 6.62658 20.3733 8.31991 22.2267L2.95991 27.5867C2.55991 27.9867 2.55991 28.64 2.95991 29.04C3.17325 29.2267 3.42658 29.3333 3.69325 29.3333C3.95991 29.3333 4.21325 29.2267 4.41325 29.0267L29.0266 4.41334C29.4399 4.01334 29.4399 3.37334 29.0266 2.97334Z"
                    fill="#20419B"
                  />
                </svg>
              </button>
              <!-- mic -->

              <!-- camera -->
              <button
                @click.prevent="camChangeStatus()"
                class="b-action mr-12 w-12 h-12 rounded-full border-0 focus:outline-none"
                :class="
                cam_status && has_video ? 'bg-blue-50' : 'bg-blue-100'
              "
                :disabled="!has_video || !room || changing_cam"
              >
                <svg
                  v-if="cam_status && has_video"
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.7971 7.19829C24.3188 6.94163 23.3154 6.67329 21.9504 7.62996L20.2354 8.84329C20.1071 5.21496 18.5321 3.79163 14.7054 3.79163H7.70542C3.71542 3.79163 2.16376 5.34329 2.16376 9.33329V18.6666C2.16376 21.35 3.62209 24.2083 7.70542 24.2083H14.7054C18.5321 24.2083 20.1071 22.785 20.2354 19.1566L21.9504 20.37C22.6738 20.8833 23.3038 21.0466 23.8054 21.0466C24.2371 21.0466 24.5754 20.9183 24.7971 20.8016C25.2754 20.5566 26.0804 19.8916 26.0804 18.2233V9.77663C26.0804 8.10829 25.2754 7.44329 24.7971 7.19829ZM12.9554 13.2766C11.7538 13.2766 10.7621 12.2966 10.7621 11.0833C10.7621 9.86996 11.7538 8.88996 12.9554 8.88996C14.1571 8.88996 15.1488 9.86996 15.1488 11.0833C15.1488 12.2966 14.1571 13.2766 12.9554 13.2766Z"
                    fill="#20419B"
                  />
                </svg>

                <svg
                  v-if="!cam_status || !has_video"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M18.3621 7.57001C18.3721 7.64001 18.3721 7.72001 18.3621 7.79001C18.3621 7.72001 18.3521 7.65001 18.3521 7.58001L18.3621 7.57001Z"
                    fill="#20419B"
                  />
                  <path
                    d="M17.9021 6.56L4.45208 20.01C3.05208 19.12 2.50208 17.53 2.50208 16V8C2.50208 4.58 3.83208 3.25 7.25208 3.25H13.2521C16.1421 3.25 17.5321 4.2 17.9021 6.56Z"
                    fill="#20419B"
                  />
                  <path
                    d="M22.0221 2.23C21.7221 1.93 21.2321 1.93 20.9321 2.23L2.47207 20.69C2.17207 20.99 2.17207 21.48 2.47207 21.78C2.62207 21.92 2.82207 22 3.01207 22C3.21207 22 3.40207 21.92 3.55207 21.77L22.0221 3.31C22.3221 3.01 22.3221 2.53 22.0221 2.23Z"
                    fill="#20419B"
                  />
                  <path
                    d="M23.0021 8.37998V15.62C23.0021 17.05 22.3021 17.62 21.9021 17.83C21.7121 17.93 21.4121 18.04 21.0421 18.04C20.6121 18.04 20.0821 17.9 19.4621 17.46L17.9821 16.42C17.9121 18.63 17.2121 19.89 15.6221 20.42C14.9821 20.65 14.1921 20.75 13.2421 20.75H7.2421C7.0021 20.75 6.7721 20.74 6.5321 20.71L15.6221 11.63L21.2721 5.97998C21.5321 5.99998 21.7421 6.07998 21.9021 6.16998C22.3021 6.37998 23.0021 6.94998 23.0021 8.37998Z"
                    fill="#20419B"
                  />
                </svg>
              </button>
              <!-- camera -->
              <!-- end call -->
              <!-- <button
                  @click="onDisconect()"
                  class="b-action mr-12 w-12 h-12 rounded-full border-0 focus:outline-none b-leave cursor-pointer bg-red-600"
              >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.73686 15.178C5.52323 14.3917 6.41069 13.7569 7.35179 13.2832C7.63919 13.1347 7.83816 12.8347 7.83813 12.4873L7.83496 9.705C10.5352 8.82387 13.4596 8.82387 16.1567 9.70183L16.163 12.4873C16.163 12.8347 16.362 13.1347 16.6493 13.2832C17.5904 13.7569 18.4779 14.3917 19.2643 15.178C19.6149 15.5286 20.177 15.5286 20.5276 15.178L22.7383 12.9673C23.0857 12.6199 23.0888 12.0546 22.7383 11.7041C16.8073 5.77311 7.1939 5.77311 1.26291 11.7041C0.912362 12.0546 0.912362 12.6168 1.26291 12.9673L3.47362 15.178C3.82417 15.5286 4.3863 15.5286 4.73686 15.178Z"
                      fill="#FBFAFF"
                  />
                </svg>
              </button>-->
              <!-- end call -->
            </div>

            <!-- Đã lưu Phiếu Tư Vấn thành công -->
            <!-- <div class="flex flex-end">
              <button type="button" class="btn px-3" @click="disconnect">Kết thúc tư vấn</button>
            </div>-->
            <div class="flex justify-between items-end mt-6">
              <div v-if="hasPdfFile" class="flex flex-col">
                <div class="fs-16 fw-400 text-black mr-7 font-bold-b">Đã có Phiếu Tư Vấn</div>
                <div
                  class="flex items-center bg-save-file fs-14 fw-400 px-2 py-1 rounded-md cursor-pointer mt-1.5"
                  @click="openPDF(consultation.pdf_file)"
                >
                  <img
                    src="../../../public/assets/images/icon/pdf-icon.svg"
                    alt
                    width="24"
                    height="24"
                    class="mr-2"
                  />
                  <span class="robo-14-400 pt-1">Phiếu tư vấn {{formatD(consultation.created_at)}}</span>
                </div>
              </div>
              <div v-else class="fs-16 fw-400 text-black mr-7 font-bold-b">Chưa có Phiếu Tư Vấn</div>

              <div v-if="hasPdfFile">
                <button
                  class="btn bg-pri bd-pri text-white fs-14 fw-600 w-48 h-12"
                  @click="onDisconect"
                >
                  <span>Kết thúc Tư vấn</span>
                </button>
              </div>
            </div>
            <!-- Đã lưu Phiếu Tư Vấn thành công -->
          </template>
          <!-- <div
            v-else
            class="d-flex"
          >
            <button
              class="btn bg-pri bd-pri text-white mt-3 mb-2 fs-14 fw-600 w-48 h-12 mr-4"
              @click="backToCall"
            >
              <span>Vào lại</span>
            </button>
            <button
              v-if="canEndCall"
              class="btn bg-pri bd-pri text-white mt-3 mb-2 fs-14 fw-600 w-48 h-12"
              @click="backToList"
            >
              <span>Kết thúc Tư vấn</span>
            </button>
          </div>-->
        </div>
        <div class="col-md-6">
          <ConsultationForm v-if="!!appointment" :appt="appointment" @refresh="refresh"></ConsultationForm>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div id="audio-wraper" class="d-none">
      <audio ref="audioJoin" id="signal-end" preload="auto">
        <source src="../../../public/assets/audio/Join.mp3" type="audio/mp3" />
      </audio>
      <audio ref="audioLeave" id="signal-end" preload="auto">
        <source src="../../../public/assets/audio/Out.mp3" type="audio/mp3" />
      </audio>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import Participant from '../../components/TwilioComponent/Participant.vue'
import LocalParticipantDiv from '../../components/TwilioComponent/LocalParticipant.vue'
import FormPhieuTuVan from '../../components/PhieuTuVan/FormPhieuTuVan.vue'
import ConsultationForm from './ConsultationForm.vue'

const {
  connect,
  createLocalVideoTrack,
  LocalVideoTrack,
  createLocalTracks,
  createLocalAudioTrack
} = require('twilio-video')
export default {
  name: 'ApptCall',
  components: {
    Participant,
    LocalParticipantDiv,
    FormPhieuTuVan,
    ConsultationForm
  },
  data () {
    return {
      room: null,
      participantsTracks: [],
      mainParticipantsTrack: null,
      room_members: [],
      room_name: '',
      has_devices: [],
      doctor_id: null,
      is_support_browser: false,
      has_video: false,
      has_audio: false,
      mic_status: false,
      cam_status: false,
      token: null,
      screen_track: null,
      is_share_screen: false,
      total_time: 0,
      canShare: false,
      changing_mic: false,
      changing_cam: false,
      appointment: null,
      logStart: null,
      logEnd: null,
      saveFile: null
    }
  },
  computed: {
    hasPdfFile () {
      return this.saveFile || this.appointment?.consultation?.pdf_file
    },
    consultation () {
      return this.saveFile || this.appointment?.consultation || {}
    }
  },
  watch: {},
  created () {
    let self = this
    window.addEventListener('unload', function (e) {
      self.disconnect(e)
    })
  },
  mounted () {
    let self = this
    this.doctor_id = this.$user.doctor.id
    self.has_devices = []
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      alert(
        'HODO không hỗ trợ dịch vụ này trên trình duyệt hiện tại, vui lòng sử dụng một trong các trình duyệt: Chrome/Microsoft Edge/Firefox'
      )
      self.is_support_browser = false
      self.backToList()
    } else {
      self.is_support_browser = true
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          devices.forEach(device => {
            self.has_devices.push(device.kind)
          })
        })
        .then(() => {
          self.has_video = !!self.has_devices.includes('videoinput')
          self.has_audio = !!self.has_devices.includes('audioinput')
          if (
            navigator.userAgent.search('Safari') >= 0 &&
            navigator.userAgent.search('Chrome') < 0
          ) {
          } else {
            self.getAppts()
          }
        })
        .catch(function (err) {
          console.log(err.name + ': ' + err.message)
        })
    }
  },
  methods: {
    getMemberJoined () {
      return this.room?.participants?.size + 1 || 1
    },
    refresh () {
      this.getAppts(true)
    },
    async getAppts (refresh = false) {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getAppts(self.$route?.params?.id)
          .then(resp => {
            self.appointment = resp.data
            if (resp && resp.data && resp.data?.room_call?.room_name) {
              self.room_name = resp.data?.room_call?.room_name
              if (resp.data?.doctor?.user) {
                self.room_members.push(resp.data?.doctor?.user)
              }
              if (resp.data?.person?.user) {
                self.room_members.push(resp.data?.person?.user)
              }
              if (!refresh) {
                self.getToken()
                self.changeStatus(1)
              }
            } else {
              self.$router.push({ path: '/doctor/patient-visit' })
              self.$toast.open({
                message: 'Tham gia thất bại vui lòng thử lại sau',
                type: 'warning'
              })
            }
          })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
      }
    },
    async changeStatus (stt) {
      if (!this.appointment) return
      await this.$rf
        .getRequest('DoctorRequest')
        .changeConsultationStatus(this.appointment.consultation.id, stt)
    },
    async getToken () {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .getTwilioToken()
        .then(r => {
          if (r && r.data && r.data.token) self.token = r.data.token
        })
        .then(() => {
          self.connectRoom()
        })
    },
    connectRoom () {
      let self = this
      let options = {
        audio: false,
        video: false
        // logLevel: 'debug'
      }
      if (self.has_video) {
        options.video = { width: 720 }
        self.cam_status = true
      }
      if (self.has_audio) {
        options.audio = true
        self.mic_status = true
      }

      createLocalTracks(options)
        .then(localTracks => {
          return connect(self.token, {
            name: self.room_name,
            tracks: localTracks
          })
        })
        .then(async room => {
          self.room = room

          // call start
          // const params = {
          //   clinic_id: this.appointment.clinic_id,
          //   person_diseases_id: this.appointment.person_diseases_id,
          //   appt_id: this.appointment.id,
          //   from_user_id: this.appointment.doctor.user_id,
          //   to_user_id: this.appointment.person?.user_id,
          //   is_videocall: self.has_video,
          //   start_time: new Date()
          // }
          // try {
          //   const data = await self.$rf
          //     .getRequest('DoctorRequest')
          //     .postTelemedicine(params)
          //   self.logStart = data.data
          //   if (self.logStart) {
          //     self.startTimeCount()
          //   }
          // } catch (error) {
          //   console.log(error)
          // }

          let pt = {
            id: room.localParticipant.sid,
            participant: room.localParticipant,
            tracks: self.getTrackDiffScreens(room.localParticipant),
            type: 'normal'
          }
          console.log(pt)
          this.mainParticipantsTrack = pt

          console.log(`Successfully joined a Room: ${self.room}`)
          // Log new Participants as they connect to the Room
          self.room.once('participantConnected', participant => {
            console.log(
              `Participant "${participant.identity}" has connected to the Room`
            )
          })

          // Log Participants as they disconnect from the Room
          self.room.once('participantDisconnected', participant => {
            console.log(
              `Participant "${participant.identity}" has disconnected from the Room`
            )
          })

          // Remote tham gia phòng
          self.room.on('participantConnected', participant => {
            console.log(`Participant connected: ${participant.identity}`)
            try {
              self.$refs.audioJoin.play()
            } catch (e) {
              console.log(e)
            }
            self.createParticipantsTracks(participant)
            // thêm video/track các remote tham gia
            participant.on('trackSubscribed', track => {
              self.trackSubscribed(track, participant)
            })
            // remove track
            participant.on('trackUnsubscribed', track => {
              self.trackUnsubscribed(track, participant)
            })
            participant.on('trackDisabled', track => {
              console.log(track)
            })
          })

          self.room.on('participantDisconnected', participant => {
            console.log(`Participant disconnected: ${participant.identity}`)
            // self.getTracks(participant).forEach(function (track) {
            //   track.detach().forEach(function (element) {
            //     element.srcObject = null
            //     element.remove()
            //   })
            // })
            try {
              self.$refs.audioLeave.play()
            } catch (e) {
              console.log(e)
            }
            self.removeParticipant(participant)
          })

          // Thêm các video/track remote đã có trong phòng
          self.room.participants.forEach(participant => {
            console.log(`Participant connected: ${participant.identity}`)
            // add khi có thêm track mới

            self.createParticipantsTracks(participant)
            // thêm video/track các remote tham gia
            participant.on('trackSubscribed', track => {
              self.trackSubscribed(track, participant)
            })
            // remove track
            participant.on('trackUnsubscribed', track => {
              self.trackUnsubscribed(track, participant)
            })
            participant.on('trackDisabled', track => {
              console.log(track)
            })
          })
        })
        .catch(error => {
          console.error(`Unable to connect to Room: ${error.message}`)
          self.$toast.open({
            message: 'Tham gia thất bại vui lòng thử lại sau',
            type: 'warning'
          })
          self.backToList()
        })
    },
    async disconnect () {
      let self = this
      this.changeStatus(2)
      this.stopTimeCount()
      if (!this.room) return

      // end
      const params = {
        ...this.logStart,
        end_time: new Date()
      }
      try {
        const data = await self.$rf
          .getRequest('DoctorRequest')
          .putTelemedicine(params.id, params)
        self.logEnd = data.data
      } catch (error) {
        console.log(error)
      }
      this.room.on('disconnected', room => {
        // Detach the local media elements
        this.room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach()
          publication.unpublish()
          publication.track.stop()
          attachedElements.forEach(element => element.remove())
          publication.track.disable()
        })
        if (self.screen_track) {
          self.room.localParticipant.unpublishTrack(self.screen_track)
          self.screen_track.stop()
        }
      })
      this.room.disconnect()
      this.mainParticipantsTrack = null
      this.participantsTracks = []
      setTimeout(() => {
        this.backToList()
      }, 300)
    },
    onDisconect () {
      let r = confirm('Bác sĩ muốn kết thúc và rời khỏi Phòng Tư vấn?')
      if (!r) return
      this.disconnect()
    },
    backToList () {
      this.$router
        .push({
          name: 'PatientVisitManagement'
          // params: { id: this.$route.params.id }
        })
        .catch(() => {})
    },
    micChangeStatus () {
      if (this.changing_mic) return
      this.changing_mic = true
      this.mic_status ? this.muteMic() : this.unmuteMic()
      this.mic_status = !this.mic_status
    },
    camChangeStatus () {
      if (this.changing_cam) return
      this.changing_cam = true
      this.cam_status ? this.disableVideo() : this.enableVideo()
      this.cam_status = !this.cam_status
    },
    muteMic () {
      const self = this
      try {
        this.room.localParticipant.audioTracks.forEach(publication => {
          publication.unpublish()
          publication.track.stop()
          if (
            self.mainParticipantsTrack.id === self.room.localParticipant.sid
          ) {
            const pt = {
              id: self.room.localParticipant.sid,
              participant: self.room.localParticipant,
              tracks: self.getTrackDiffScreens(self.room.localParticipant),
              type: 'normal'
            }
            self.mainParticipantsTrack = pt
          } else {
            const lcP = self.participantsTracks.findIndex(
              p => p.id === self.room.localParticipant.sid
            )
            if (lcP > -1) {
              self.participantsTracks[lcP] = {
                id: self.room.localParticipant.sid,
                participant: self.room.localParticipant,
                tracks: self.getTrackDiffScreens(self.room.localParticipant),
                type: 'normal'
              }
            }
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.changing_mic = false
      }
    },
    unmuteMic () {
      this.createAudioTrack()
    },
    disableVideo () {
      const self = this
      try {
        this.room.localParticipant.videoTracks.forEach(publication => {
          if (
            publication.track.name !==
            'screen' + self.room.localParticipant.sid
          ) {
            publication.unpublish()
            publication.track.stop()
            if (
              self.mainParticipantsTrack.id === self.room.localParticipant.sid
            ) {
              const pt = {
                id: self.room.localParticipant.sid,
                participant: self.room.localParticipant,
                tracks: self.getTrackDiffScreens(self.room.localParticipant),
                type: 'normal'
              }
              self.mainParticipantsTrack = pt
            } else {
              const lcP = self.participantsTracks.findIndex(
                p => p.id === self.room.localParticipant.sid
              )
              if (lcP > -1) {
                self.participantsTracks[lcP] = {
                  id: self.room.localParticipant.sid,
                  participant: self.room.localParticipant,
                  tracks: self.getTrackDiffScreens(self.room.localParticipant),
                  type: 'normal'
                }
              }
            }
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.changing_cam = false
      }
    },
    enableVideo () {
      this.createVideoTrack()
    },
    createAudioTrack () {
      const self = this
      if (self.has_audio) {
        createLocalAudioTrack().then(audio => {
          self.room.localParticipant
            .publishTrack(audio)
            .then(() => {
              if (
                self.mainParticipantsTrack.id === self.room.localParticipant.sid
              ) {
                const tracks = self.getTrackDiffScreens(
                  self.room.localParticipant
                )
                const pt = {
                  id: self.room.localParticipant.sid,
                  participant: self.room.localParticipant,
                  tracks: tracks,
                  type: 'normal'
                }
                self.mainParticipantsTrack = pt
              } else {
                const lcP = self.participantsTracks.findIndex(
                  p => p.id === self.room.localParticipant.sid
                )
                if (lcP > -1) {
                  self.participantsTracks[lcP] = {
                    id: self.room.localParticipant.sid,
                    participant: self.room.localParticipant,
                    tracks: self.getTrackDiffScreens(
                      self.room.localParticipant
                    ),
                    type: 'normal'
                  }
                }
              }
            })
            .then(() => {
              self.changing_mic = false
            })
        })
      }
    },
    createVideoTrack () {
      const self = this
      if (self.has_video) {
        createLocalVideoTrack().then(video => {
          self.room.localParticipant
            .publishTrack(video)
            .then(() => {
              if (
                self.mainParticipantsTrack.id === self.room.localParticipant.sid
              ) {
                const tracks = self.getTrackDiffScreens(
                  self.room.localParticipant
                )
                const pt = {
                  id: self.room.localParticipant.sid,
                  participant: self.room.localParticipant,
                  tracks: tracks,
                  type: 'normal'
                }
                self.mainParticipantsTrack = pt
              } else {
                const lcP = self.participantsTracks.findIndex(
                  p => p.id === self.room.localParticipant.sid
                )
                if (lcP > -1) {
                  self.participantsTracks[lcP] = {
                    id: self.room.localParticipant.sid,
                    participant: self.room.localParticipant,
                    tracks: self.getTrackDiffScreens(
                      self.room.localParticipant
                    ),
                    type: 'normal'
                  }
                }
              }
            })
            .then(() => {
              self.changing_cam = false
            })
        })
      }
    },
    getTracks (participant) {
      let self = this
      return Array.from(participant.tracks.values())
        .filter(function (publication) {
          if (self.isStreamTrack(publication.track)) return publication.track
        })
        .map(function (publication) {
          if (self.isStreamTrack(publication.track)) return publication.track
        })
    },
    getTrackDiffScreens (participant) {
      let self = this
      return Array.from(participant.tracks.values())
        .filter(function (publication) {
          if (
            !self.isScreen(publication.trackName) &&
            self.isStreamTrack(publication.track)
          ) {
            return publication.track
          }
        })
        .map(function (publication) {
          if (
            !self.isScreen(publication.trackName) &&
            self.isStreamTrack(publication.track)
          ) {
            return publication.track
          }
        })
    },

    isScreen (name) {
      return name?.includes('screen')
    },
    isStreamTrack (t) {
      return t?.kind === 'video' || t?.kind === 'audio'
    },
    async shareScreenHandler () {
      let self = this
      if (!self.screen_track) {
        let stream = await self.getUserScreen()
        if (!stream) return
        let screenName = 'screen' + self.room?.localParticipant?.sid
        self.screen_track = LocalVideoTrack(stream.getTracks()[0], {
          name: screenName
        })
        self.room.localParticipant.publishTrack(self.screen_track)
        self.is_share_screen = true
        self.screen_track.mediaStreamTrack.onended = () => {
          self.shareScreenHandler()
        }
        self.createLocalShare(self.screen_track)
      } else {
        if (
          this.mainParticipantsTrack &&
          this.mainParticipantsTrack.id ===
            'screen' + this.room.localParticipant.sid
        ) {
          this.setMeToMain()
        } else {
          let idxPt = this.participantsTracks.findIndex(
            pt => pt.type === 'screen' + this.room.localParticipant.sid
          )
          this.participantsTracks.splice(idxPt, 1)
        }
        self.room.localParticipant.unpublishTrack(self.screen_track)
        self.screen_track.stop()
        self.screen_track = null
        self.is_share_screen = false
      }
    },
    zoomPTrack (p) {
      if (this.mainParticipantsTrack) {
        this.participantsTracks.push(this.mainParticipantsTrack)
      }
      let currP = this.participantsTracks.findIndex(
        _p => _p.type === p.type && _p.id === p.id
      )
      this.participantsTracks.splice(currP, 1)
      setTimeout(() => {
        this.mainParticipantsTrack = null
      }, 20)
      setTimeout(() => {
        this.mainParticipantsTrack = p
      }, 30)
    },
    trackSubscribed (track, participant) {
      if (track.name === 'screen' + participant.sid) {
        if (this.mainParticipantsTrack) {
          this.participantsTracks.push(this.mainParticipantsTrack)
        }
        setTimeout(() => {
          let pt = {
            id: 'screen' + participant.sid,
            participant,
            tracks: [track],
            type: 'screen'
          }
          this.mainParticipantsTrack = pt
        }, 50)
      } else {
        let tracks = this.getTrackDiffScreens(participant)
        let p = this.participantsTracks.find(_p => _p.id === participant.sid)
        if (p) {
          p.tracks = tracks
        } else {
          let pt = {
            id: participant.sid,
            participant,
            tracks: tracks,
            type: 'normal'
          }
          this.participantsTracks.push(pt)
        }
      }
    },

    trackUnsubscribed (track, participant) {
      if (track.name === 'screen' + participant.sid) {
        if (
          this.mainParticipantsTrack &&
          this.mainParticipantsTrack.id === 'screen' + participant.sid
        ) {
          this.setMeToMain()
        } else {
          const p = this.participantsTracks.findIndex(
            _p => _p.id === 'screen' + participant.sid
          )
          this.participantsTracks.splice(p, 1)
        }
      } else {
        let p = null
        if (
          this.mainParticipantsTrack &&
          this.mainParticipantsTrack.participant.sid === participant.sid
        ) {
          p = this.mainParticipantsTrack
        } else {
          p = this.participantsTracks.find(_p => _p.id === participant.sid)
        }
        if (p) {
          const t = p.tracks.findIndex(_t => _t.name === track.name)
          p.tracks.splice(t, 1)
        }
      }
    },
    removeParticipant (participant) {
      if (
        this.mainParticipantsTrack &&
        this.mainParticipantsTrack.participant.sid === participant.sid
      ) {
        this.setMeToMain()
      }
      const filered = this.participantsTracks.filter(
        p => p.id !== participant.sid && p.id !== 'screen' + participant.sid
      )
      // if (this.mainParticipantsTrack && this.mainParticipantsTrack.participant.sid === participant.sid) {
      //   this.mainParticipantsTrack = null
      // }
      this.participantsTracks = filered
    },
    createLocalShare (stream) {
      if (this.mainParticipantsTrack) {
        this.participantsTracks.push(this.mainParticipantsTrack)
      }
      let pt = {
        id: 'screen' + this.room.localParticipant.sid,
        participant: this.room.localParticipant,
        tracks: [stream],
        type: 'screen'
      }
      this.mainParticipantsTrack = pt
    },
    isFirefox () {
      var mediaSourceSupport = !!navigator.mediaDevices.getSupportedConstraints()
        .mediaSource
      var matchData = navigator.userAgent.match('Firefox')
      var firefoxVersion = 0
      if (matchData && matchData[1]) {
        firefoxVersion = parseInt(matchData[1], 10)
      }
      return mediaSourceSupport && firefoxVersion >= 52
    },
    isChrome () {
      return 'chrome' in window
    },
    canScreenShare () {
      return this.isFirefox() || this.isChrome()
    },
    async getDoctorInfo (id) {
      let self = this
      let exitst = null
      if (self.member_joined && self.member_joined.length) {
        exitst = self.member_joined.find(d => d.id === id)
      }
      if (!exitst) {
        await self.$rf
          .getRequest('DoctorRequest')
          .getDoctorInfo(id)
          .then(r => {
            self.member_joined.push(r.data)
          })
      }
    },
    async getUserScreen () {
      if (!this.canScreenShare()) {
        alert('Trình duyệt của bạn không hỗ trợ tính năng này!')
        return
      }
      if (this.isChrome()) {
        return navigator.mediaDevices
          .getDisplayMedia()
          .then(stream => {
            return stream
          })
          .catch(e => {
            console.log(e)
          })
      } else if (this.isFirefox()) {
        return navigator.mediaDevices.getUserMedia({
          video: {
            mediaSource: 'screen'
          }
        })
      }
    },
    isArray (p) {
      return Array.isArray(p)
    },
    startTimeCount () {
      this.total_time = 0
      setTimeout(() => {
        this.start_count = setInterval(this.setTime, 1000)
      }, 30)
    },
    stopTimeCount () {
      clearInterval(this.start_count)
    },
    setTime () {
      this.total_time++
    },
    fancyTimeFormat (duration) {
      // Hours, minutes and seconds
      var hrs = ~~(duration / 3600)
      var mins = ~~((duration % 3600) / 60)
      var secs = ~~duration % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''

      if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
      }

      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    },
    setMeToMain () {
      let myTracks = null
      let idsMyTracks = -1
      this.participantsTracks.some((pt, i) => {
        if (pt.id === this.room.localParticipant.sid) {
          myTracks = pt
          idsMyTracks = i
          return true
        }
        return false
      })
      if (myTracks) {
        this.mainParticipantsTrack = myTracks
        this.participantsTracks.splice(idsMyTracks, 1)
      } else {
        this.mainParticipantsTrack = this.participantsTracks[0]
        this.participantsTracks.splice(0, 1)
      }
    },
    createParticipantsTracks (p) {
      const pt = {
        id: p.sid,
        participant: p,
        tracks: this.getTrackDiffScreens(p),
        type: 'normal'
      }
      this.participantsTracks.push(pt)
    },
    formatTime (time) {
      return time
        ? window.moment.utc(time * 1000).format('HH:mm:ss')
        : '00:00:00'
    },
    backOrg () {
      this.$router.push({
        name: 'AppointmentDetail',
        params: { id: this.$route.params.id }
      })
    },
    saveComplete (data) {
      if (data) this.saveFile = data
    },
    backToCall () {
      this.connectRoom()
      this.logEnd = null
    },
    endCall () {
      this.$router.push({ name: 'AppointmentManagement' })
    },
    formatD (date_time) {
      return window.moment(date_time).format('DD/MM/YY')
    },
    openPDF (path) {
      let url = appUtils.getDocumentURL(path)
      window.open(url, '_blank').focus()
      window.event.stopPropagation()
    }
  },
  beforeDestroy () {
    this.disconnect()
  }
}
</script>

<style scoped>
.main-media {
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
}
.appt-media-wraper {
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 3;
}
.participant-media {
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.participant-media-item {
  width: 30%;
  aspect-ratio: 16 / 9;
  margin-right: 16px;
}
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.spinner-border {
  width: 3rem;
  height: 3rem;
}

.bottom-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scroll-end {
  height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.bg-save-file {
  background-color: #ffd6c3;
}
</style>
